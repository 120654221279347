<template>
  <div class="author">
    <div class="author__wrapper">
      <Row>
        <Column class="author__avatar-holder">
          <img :src="params.images[0]"
               alt=""
               class="author__avatar"/>
        </Column>
        <Column>
          <span class="author__name">{{params.name}}</span>
          <span class="author__post">{{params.post}}</span>
        </Column>
      </Row>
    </div>
  </div>
</template>

<script>
export default {
  name: 'Author',
  props: {
    params: {
      src: {
        type: String,
        required: true,
      },
      name: {
        type: String,
        required: true,
      },
      post: {
        type: String,
        required: true,
      },
      type: Object,
      required: true,
    },
  },
};
</script>
